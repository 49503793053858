import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

/**
 * Bootstrap tooltip widget
 * @see {@link https://getbootstrap.com/docs/4.1/components/tooltips/|Bootstrap Docs}
 *
 * @example Basic tooltip with text from the `title` attribute
 *   <button data-controller='tooltip' title='Button to do magic'>
 *     Do Magic
 *   </button>
 *
 * @example Tooltip positioned on the bottom
 *   <button data-controller='tooltip'
 *           title='Button to do magic'
 *           placement='bottom'>
 *     Do Magic
 *   </button>
 *
 * @example Tooltip with left aligned text
 *   <button data-controller='tooltip'
 *           title='Button to do magic'
 *           data-tooltip-body-class='text-left'>
 *     Do Magic
 *   </button>
 */
export default class extends Controller {
  static targets = ["manualTrigger"];
  static values = {
    // How tooltip is triggered - click | hover | focus | manual.
    // You may pass multiple triggers; separate them with a space.
    // It's best to have focus along with hover for accessibility.
    trigger: { type: String, default: 'hover focus' },
  }
  static classes = ["outer", "body"]

  initialize() {
    if (this.hasManualTriggerTarget) this.triggerValue = 'manual'

    $(this.element).tooltip({
      trigger: this.triggerValue,
      template: `<div class="tooltip ${this.outerClasses.join(' ')}" role="tooltip"><div class="arrow"></div><div class="tooltip-inner ${this.bodyClasses.join(' ')}"></div></div>`
    });
  }

  toggleEnabled() {
    $(this.element).tooltip("toggleEnabled");
  }

  enable() {
    $(this.element).tooltip("enable");
  }

  disable() {
    $(this.element).tooltip("disable");
  }

  toggle(disable) {
    if (disable) {
      this.disable();
    } else {
      this.enable();
    }
  }

  show() {
    const element = $(this.element);
    element.tooltip("show");
    this.hide(element);
  }

  hide(element) {
    setTimeout(function () {
      element.tooltip("hide");
    }, 2000);
  }

  force_hide() {
    $(this.element).tooltip("hide");
  }

  // Private

  /**
   * Handle when controller disconnects.
   */
  disconnect() {
    // If we don't force hide on disconnect, a tooltip can be left orphaned on
    // when the element the controller is attached to is removed from the page.
    this.force_hide();
  }
}
